import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import EachBookingWrapper from '../Pages/Bookings/EachBookingWrapper';
import EachPurchasedCardDetails from '../Pages/GiftCards/EachPurchasedCardDetails';
import PurchasedCards from '../Pages/GiftCards/PurchasedCards';
import VerifyCards from '../Pages/GiftCards/VerifyCards';
import Home from '../Pages/Home';
import AuthContext from '../store/auth-context';



const MainNavigator = () => {
    const authCtx = useContext(AuthContext);

    // console.log(authCtx)

    if(!authCtx.isLoggedIn) {
        return(
            <Routes>
                <Route path="*" element={<Home />}></Route>
                <Route path="/gc/verify" element={<VerifyCards />}></Route>
            </Routes>
        )
    } else if (authCtx.isLoggedIn) {
        return (
            <Routes>
                <Route path="*" element={<Home />}></Route>
                <Route path="/gift-cards" element={<Home />}></Route>
                {/* <Route path="/gc/verify" element={<VerifyCards />}></Route> */}
                <Route path="/gc/verify" element={<PurchasedCards />}></Route>
                <Route path="/gc/my-cards" element={<PurchasedCards />}></Route>
                <Route path="/mycards/:issuedCardNumber" element={<EachPurchasedCardDetails />}></Route>
                <Route path="/visits/:sessionIdentifier" element={<EachBookingWrapper />}></Route>
            </Routes>
        )
    } else {
        return(
            <Routes>
                <Route path="*" element={<Home />}></Route>
            </Routes>
        )
    }

}

export default MainNavigator